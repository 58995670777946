const service = [
    {
        path: '/service/manage',
        name: 'serviceManage',
        component: () => import('@/view/service/manage'),
    },
    // {
    //     path: '/lanxin/url',
    //     name: 'lanxinUrl',
    //     component: () => import('@/view/banner/url'),
    // },
]
export default service