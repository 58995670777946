<template>
    <div class="main-content">
        <pagesHeader title="菜单设置" />
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="menu" slot-scope="{ row }">
                <el-button size="small" type="text" @click="changeEdit(row.id)">编辑</el-button>
            </template>
        </avue-crud>
        <Increase v-if="increase.type" :increase="increase"/>
    </div>
</template>

<script>
    import column from "./column";
    import Increase from "./increase";
    import { systemManageList } from "@/api/system/list";
    export default {
        name: "identity",
        components: {
            Increase
        },
        data(){
            return{
                increase: {
                    type: false,
                    id: ''
                },
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menuWidth: 200,
                    index: false,
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeIdentityIncrease', (n)=>{
                this.increase =  {
                    type: false,
                    id: ''
                };
                if(n){
                    this.GET_identityList();
                }
            })
        },
        methods: {
            onLoad(){
                this.GET_identityList();
            },
            GET_identityList(){
              systemManageList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name, //用户身份名称
                }).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.increase.type = true;
                this.increase.id = id;
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_identityList();
            },
            changeIncrease(){
                this.increase.type = true;
                this.increase.id = '';
            }
        }
    }
</script>

<style scoped>

</style>
