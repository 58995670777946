const column = {
    column: [
        {
            label: '用户头像',
            prop: 'avatarUrl',
            slot: true
        },
        {
            label: '用户昵称',
            prop: 'nickname'
        },
        {
            label: '用户手机号',
            prop: 'phone'
        },
        {
            label: '注册时间',
            prop: 'createTime'
        },
        {
            label: '账号状态',
            prop: 'state',
            slot: true
        }
    ],
    state: [
        {
            label: '正常',
            value: 1
        },
        {
            label: '禁用',
            value: 2
        }
    ],
}
export default column
