import {indexButton} from "@/api";
import {Message} from "element-ui";

const index = {
    state: {
        menu: [],
        menuSubButtons: [],
        router: '',
        SubMenuIndex: '',
        tableTabs: [{
            title: '首页',
            name: '0',
            url: '/',
            index: '1'
        }],

        button: [] // 全局按钮控制
    },
    getters: {
        getButton(state) {
            return state.button
        }
    },
    mutations: {
        //缓存菜单
        setMenu(state,info) {
            state.menu = info;
        },
        //缓存路由
        setRouter(state,info){
            state.router = info;
        },
        // 缓存sun-menu index
        setSubMenuIndex(state,index){
            state.SubMenuIndex = index
        },
        setMenuSubButtons(state,index){
            state.menuSubButtons = index
        },
        setTableTabs(state,info){
            state.tableTabs = info
        },
        setButton(state,data){
            state.button = data
        }
    },
    actions: {
        allButton({commit},type){
            // // console.log(type)
            if(type === 'request'){
                indexButton().then(res=>{
                    if(res.code === 200){
                        commit('setButton',res.data)
                    }else{
                        Message.error(res.msg);
                    }
                })
            }else{
                commit('setButton',[])
            }
        }
    }
}
export default index