<template>
  <el-dialog
    :title="choiceId ? '修改管理员' : '新增管理员'"
    :visible.sync="dialogVisible"
    width="60%"
    v-dialogdrag
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
      @submit="formSubmit"
    >
      <template slot="role">
        <el-select v-model="form.role" multiple placeholder="请选择">
          <el-option
            v-for="item in role"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.state == 2 ? true : false"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="headPortrait">
        <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :limit="1"
          :maxSize="5"
          accept="image/jpeg,image/png,image/jpg"
          :multiple="true"
        />
        <div class="tipImg">尺寸推荐:96X96，格式：jpeg,png,jpg</div>
      </template>
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        <el-button type="primary" icon="el-icon-check" @click="submit(form)"
          >确定</el-button
        >
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { actionUrl, imageUrl } from "@/config/public";
import { userAdd, userUpdate, userView } from "@/api/system/index";
import { enterNumberEnglish } from "@/utils/formRules";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import {getStoreAllList} from "@/api/user/list";
export default {
  name: "add",
  props: {
    dialogVisible: Boolean,
    choiceId: String,
  },
  components: {
    FormImgUpload,
  },
  data() {
    return {
      form: {
        headPortrait: [],
      },
      role: this.$store.state.system.role,
      parent: [], //归属上级
      stoerList:[],
      roleType:localStorage.getItem('roleType'),
      roleTypeArrOne:[
      {
            label: "门店权限",
            prop: "storeAuthority",
            type: "radio",
            row: true,
            dicData: [
              { label: "全部", value: 1 },
              { label: "部分", value: 2 },
              { label: "无", value: 3 }
            ],
            change: ({ value, column }) => {
              console.log('123',value)
              if (value === 2 && this.option.column.length == 9) {
                this.option.column.push({
                  label: "门店集合",
                  prop: "storeList",
                  type: "select",
                  dicData: this.stoerList,
                  row: true,
                  multiple: true,
                  filterable:true,
                  props: {
                    label: "name",
                    value: "id",
                  },
                });
              }
              if ((value === 1||value === 3) && this.option.column.length == 10) {
                this.option.column.pop();
              }
            },
            rules: [
              {
                required: true,
                message: "请选择门店权限",
                trigger: "blur",
              },
            ],
          },
      ],
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "管理员账号",
            prop: "username",
            disabled:false,
            maxlength: 20,
            // showWordLimit: true,
            placeholder: "请输入管理员账号",
            row: true,
            rules: [
              {
                required: true,
                message: "请输入管理员账号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "管理员密码",
            prop: "password",
            maxlength: 20,
            type: "password",
            disabled:false,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员密码",
                trigger: "blur",
              },
              ...enterNumberEnglish,
            ],
          },
          {
            label: "确认密码",
            type: "password",
            prop: "confrimPassword",
            disabled:false,
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入确认密码",
                trigger: "blur",
              },
              {
                validator: (_r, value) => {
                  const { password } = this.form;
                  if (password) {
                    const isValid = !!value && value === password;
                    return isValid
                      ? Promise.resolve()
                      : Promise.reject(new Error("密码不一致"));
                  }
                },
              },
            ],
          },
          {
            label: "管理员姓名",
            prop: "fullName",
            maxlength: 20,
            row: true,
            // showWordLimit: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "头像",
            row: true,
            type: "text",
            prop: "headPortrait",
            span: 24,
            rules: [
              {
                required: false,
                message: "请上传头像",
                trigger: "blur",
              }
            ],
          },
          // {
          //   label: "管理员邮箱",
          //   prop: "email",
          //   maxlength: 100,
          //   row: true,
          //   placeholder: "请输入管理员邮箱",
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入管理员邮箱",
          //       trigger: "blur",
          //     },
          //     ...emailRegex,
          //   ],
          // },
          {
            label: "管理员角色",
            prop: "roleList",
            type: "select",
            dicData: this.$store.state.system.role,
            row: true,
            multiple: true,
            rules: [
              {
                required: true,
                message: "请输入管理员角色",
                trigger: "blur",
              },
            ],
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: "状态",
            prop: "state",
            type: "radio",
            row: true,
            dicData: [
              { label: "启用", value: 1 },
              { label: "禁用", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "是否脱敏",
            prop: "desensitization",
            type: "radio",
            row: true,
            dicData: [
              { label: "脱敏", value: 1 },
              { label: "不脱敏", value: 2 },
            ],
            rules: [
              {
                required: true,
                message: "请选择是否脱敏",
                trigger: "blur",
              },
            ],
          }
        ],
      },
    };
  },
  mounted() {
    if (this.choiceId) {
      userView(this.choiceId).then((res) => {
        this.form = {
          ...res.data,
          headPortrait: [res.data.headPortrait],
          password:'Zz11111111',
          confrimPassword:'Zz11111111',
        };
        this.option.column[0].disabled = true
        this.option.column[1].disabled = true
        this.option.column[2].disabled = true
        // console.log(res.data)
      });
    }
    if(this.roleType==1){
      this.option.column=this.option.column.concat(this.roleTypeArrOne)
    }
    this.getAllStore()
  },
  methods: {
    getAllStore(){
      getStoreAllList().then((res) => {
          this.stoerList = res.data
      });
    },
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.headPortrait = res.image;
      // this.$nextTick(() => {
      //   this.form.headPortrait = res.path;
      // });
    },
    handleClose() {
      // this.$confirm("确认关闭？").then((res) => {
      this.$root.eventHub.$emit("changeAdd", false);
      // });
    },
    formSubmit(form, done) {},
    // 提交
    submit(form) {
      console.log("222222222", form.roleList);
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.choiceId) {
            userUpdate({
              id: this.choiceId,
              email: form.email,
              fullName: form.fullName,
              headPortrait: form.headPortrait[0],
              username: form.username,
              // password: form.password,
              phone: form.phone,
              state: form.state,
              roleList: form.roleList,
              parentId: form.parentId,
              desensitization:form.desensitization,
              storeAuthority:form.storeAuthority,
              storeList:form.storeList,
              type:Number(this.roleType)
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          } else {
            userAdd({
              email: form.email,
              fullName: form.fullName,
              headPortrait: form.headPortrait[0],
              username: form.username,
              password: form.password,
              phone: form.phone,
              state: form.state,
              roleList: form.roleList,
              parentId: form.parentId,
              desensitization:form.desensitization,
              storeAuthority:form.storeAuthority,
              storeList:form.storeList,
              type:Number(this.roleType)
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  that.$message.success(res.msg);
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    // 关闭
    handleClose() {
      this.$root.eventHub.$emit("changeAdd", true);
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },
  },
};
</script>

<style scoped>
</style>
