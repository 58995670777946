<template>
    <div class="main-content">
        <pagesHeader title="用户列表" />
        <Search @changeSearch="changeSearch" @changeIncrease="changeIncrease"/>
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="state" slot-scope="{ row }">
                <el-switch v-model="row.state" @change="switchChange(row.id,row.state)"></el-switch>
            </template>
          <template slot="avatarUrl" slot-scope="{ row }">
            <img v-if="row.avatarUrl" style="width: 39px;height: 39px;display: block;margin: 0 auto" :src="row.avatarUrl" alt="">
            <img v-else style="width: 39px;height: 39px;display: block;margin: 0 auto" src="../../../style/image/user.png" alt="">
          </template>
        </avue-crud>
        <Increase v-if="increase.type" :increase="increase"/>
    </div>
</template>

<script>
    import column from "./column";
    import Search from "./search";
    import { userManageList,userManageStateUpdate } from "@/api/user/list";
    export default {
        name: "identity",
        components: {
            Search
        },
        data(){
            return{
                increase: {
                    type: false,
                    id: ''
                },
                data: [],
                option: {
                    align: 'center',
                    menuAlign: 'center',
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    header: false,
                    border: true,
                    menu: false,
                    menuWidth: 200,
                    index: true,
                    indexLabel: '序号',
                    indexWidth: 100,
                    column: column.column
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                },
                search: {},
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeIdentityIncrease', (n)=>{
                this.increase =  {
                    type: false,
                    id: ''
                };
                if(n){
                    this.GET_identityList();
                }
            })
        },
        methods: {
            onLoad(){
                this.GET_identityList();
            },
            GET_identityList(){
                userManageList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name, //用户身份名称
                    state: this.search.state, //用户身份名称
                    createTimeStart: this.search.createTimeStart, //申请时间开始
                    createTimeEnd: this.search.createTimeEnd, //申请时间结束
                }).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        res.data.records.forEach(item=>{
                            item.state = item.state == 1 ? true : false
                        })
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            switchChange(e,state){
                // console.log(e,state)
                userManageStateUpdate({
                    id: e,
                    state: state ? 1:2
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.GET_identityList();
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            changeEdit(id){
                // console.log('编辑',id)
                this.increase.type = true;
                this.increase.id = id;
            },
            changeSearch(e){
                // console.log(e)
                this.search = e;this.pageSync.currentPage = 1;
                this.GET_identityList();
            },
            changeIncrease(){
                this.increase.type = true;
                this.increase.id = '';
            }
        }
    }
</script>

<style scoped>

</style>
