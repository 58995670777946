import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 平台登录
import platformLogin from '@/pages/login/platformLogin';
//供应商登录
import supplierLogin from '@/pages/login/supplierLogin';
// // 首页
import index from '@/pages/index/index';
// // 首页



// view 内容
// index首页
import indexHome from '@/view/index/index';

// 门店首页
import indexStoreHome from '@/view/index/storeIndex';

// 供应商首页
import indexSupplierHome from '@/view/index/supplierIndex';

//403页面
import errorPage from '@/view/errorPage/index';

// user 用户管理
import userManage from '@/view/user/user/list';

//banner管理
import banner from "./view/banner";

//商品管理
import goods from "./view/goods";
//订单管理
import order from "./view/order";
//供应商
import supplierGoods from "./view/supplierGoods";
//供应商订单列表
import supplierOrder from "./view/supplierOrder";
//平台-供应商
import supplier from "./view/supplier";
//用户
import user from "./view/user";
//设置
import system from "./view/system";
//平台财务
import finance from "./view/finance";

import store from "./view/store";
import market from "./view/market";

//运费模板
import freight from "./view/freight";

//用户列表
import storeMember from "./view/storeMember";
import storeOrder from "./view/storeOrder";
import service from "./view/service";


// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';
import systemMenu from '@/view/system/menu/list';

//页面装修
import decorate from './view/decorate';
//余额管理
import balance from './view/balance';


export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/platformLogin',
			name: 'platformLogin',
			component: platformLogin,
		},
		{
			path: '/supplierLogin',
			name: 'losupplierLogingin',
			component: supplierLogin,
		},
		{
			path: '/',
			component: index,
			children: [
				// {
				// 	path: '/',
				// 	name: 'indexHome',
				// 	component: indexHome
				// },
				{
					path: '/home/control',
					name: 'indexHome',
					component: indexHome
				},
				{
					path: '/storeHome/control',
					name: 'storeHomeControl',
					component: indexStoreHome
				},
				{
					path: '/supplierHome/control',
					name: 'supplierHomeControl',
					component: indexSupplierHome
				},
				{
					path: '/errorPage',
					name: 'errorPage',
					component: errorPage
				},
				// ------------------------------------------------------ 系统管理
				{
					path: '/system/authority',
					name: 'systemAuthority',
					component: systemUser
				},
				{
					path: '/system/authority/user',
					name: 'systemAuthorityUser',
					component: systemUser
				},
				{
					path: '/system/authority/role',
					name: 'role',
					component: systemRole
				},
				...banner,
				...goods,
				...order,
				...user,
				...system,
				...store,
				...supplierGoods,
				...supplierOrder,
				...supplier,
				...finance,
				...storeMember,
				...storeOrder,
				...decorate,
				...balance,
				...market,
				...service,
				...freight,
				{
					path: '/user/manage',
					name: 'userManage',
					component: userManage
				},
				{
					path: '/system/menu',
					name: 'systemMenu',
					component: systemMenu
				},{
					path: '/password',
					name: 'password',
					component: () => import('@/pages/password/index')
				}
			]
		}
	]
})
