const market = [
    {
        path: '/market/activity',
        name: '/marketActicity',
        component: () => import('@/view/market/activity'),
    },
    {
        path: '/market/poster',
        name: '/marketPoster',
        component: () => import('@/view/market/poster'),
    },
    {
        path: '/market/activityGoods',
        name: '/marketActivityGoods',
        component: () => import('@/view/market/activityGoods'),
    },
    {
        path: '/market/create',
        name: 'marketCreate',
        component: () => import('@/view/market/list/create'),
    }
]
export default market