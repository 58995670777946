const supplier = [
    {
        path: '/supplier/list',
        name: 'supplierList',
        component: () => import('@/view/supplier/list'),
    },
    {
        path: '/supplier/order',
        name: 'supplierOrder',
        component: () => import('@/view/supplier/order'),
    },
    {
        path: '/supplier/orderDetails',
        name: 'supplierOrder',
        component: () => import('@/view/supplier/orderDetails'),
    },
]
export default supplier