const storeMember = [
    {
        path: '/storeMember/list',
        name: 'membestoreMemberrlist',
        component: () => import('@/view/storeMember/list'),
    },
    {
        path: '/storeMember/department',
        name: 'membestoreMemberDepartment',
        component: () => import('@/view/storeMember/department'),
    },
    {
        path: '/storeMember/integral',
        name: 'membestoreMemberIntegralt',
        component: () => import('@/view/storeMember/integral'),
    },
    {
        path: '/storeMember/label',
        name: 'membestoreMemberLabel',
        component: () => import('@/view/storeMember/label'),
    }
]
export default storeMember