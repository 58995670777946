const banner = [
    {
        path: '/banner/pc',
        name: 'bannerpc',
        component: () => import('@/view/banner/pc'),
    },
    // {
    //     path: '/lanxin/url',
    //     name: 'lanxinUrl',
    //     component: () => import('@/view/banner/url'),
    // },
]
export default banner