<template>
  <div>
    <el-container class="container">
      <el-header height="60px" class="header">
        <Header :isCollapse="isCollapse"></Header>
        <!-- <IndexNav></IndexNav> -->
      </el-header>
    </el-container>
    <el-container class="html">
      <el-aside
        class="el-aside"
        :width="width"
        :class="isCollapse ? 'el-aside-old' : 'el-aside-new'"
      >
        <Menu></Menu>
      </el-aside>
      <el-container class="container routerBj">
        <el-main>
          <keep-alive :include="list">
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Menu from "@/pages/menu/menu.vue";
import Header from "@/pages/header/header.vue";
import IndexNav from "@/pages/indexNav/indexNav.vue";
export default {
  name: "index",
  components: {
    Menu,
    Header,
    IndexNav,
  },
  data() {
    return {
      width: "190px",
      isCollapse: false, //导航菜单展开，收缩
      routerViewName: "",
      title: this.$title,
      list: ["1"],
    };
  },
  computed: {
    pageInfo() {
      return this.$store.state.user.keepList;
    },
  },
  watch: {
    isCollapse(valueNew) {
      // console.log(valueNew)
      let that = this;
      if (valueNew) {
        that.width = "64px";
        that.title = "";
      } else {
        that.width = "190px";
        setTimeout(() => {
          that.title = this.$title;
        }, 200);
      }
    },
    pageInfo: {
      handler(nval, oval) {
        if(this.$store.state.user.keepList[0]){
            this.list[1] = this.$store.state.user.keepList[0]
            console.log('this.list',this.list)
            this.$forceUpdate()
        }else{
            this.list[1] = null
        }
    
      },
      deep: true, // 深度监听
    },
  },
  mounted() {
    this.$root.eventHub.$on("changeDate", this.changeDate);
    console.log("12312", this.$store.state.user.keepList);
  },
  methods: {
    changeDate() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style scoped lang="scss">
.html {
  height: calc(100vh - 60px);
  overflow-y: hidden;
}

.logo {
  height: 60px;
  font-size: 20px;
  color: hsla(0, 0%, 100%, 0.8);
  line-height: 60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  background-color: #20222a;
}

.el-aside {
  overflow: hidden;
}

.container {
  background-color: #ffffff;
}

.header {
  background-color: #303133;
}
.routerBj {
  background-color: #f0f2f5;
}
.el-main {
  padding: 10px !important;
}

.el-main::-webkit-scrollbar {
  display: none;
}
</style>
