const order = [
    {
        path: '/system/applet/config',
        name: '/systemappletconfig',
        component: () => import('@/view/system/applet/config'),
    },
    {
        path: '/system/banner',
        name: '/systembanner',
        component: () => import('@/view/system/banner'),
    },
    {
        path: '/system/agreement',
        name: '/systemagreement',
        component: () => import('@/view/system/agreement'),
    },{
        path: '/system/order/config',
        name: '/systemOrderConfig',
        component: () => import('@/view/system/orderSetting'),
    },{
        path: 'system/serive',
        name: '/systemSerive',
        component: () => import('@/view/system/service'),
    },{
        path: 'system/log',
        name: 'system/log',
        component: () => import('@/view/system/log'),
    },{
        path: 'system/express/company',
        name: 'systemExpressCompany',
        component: () => import('@/view/system/expressCompany'),
    },{
        path: 'system/regionRestrict',
        name: 'systemRegionRestrict',
        component: () => import('@/view/system/regionRestrict'),
    }
]
export default order