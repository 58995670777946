/**
 * 生成唯一标识符
 *
 * @export
 * @returns
 */
export function guid() {
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}
function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function getIndex(index, pageNum, pageSize) {
  if (pageNum == 0) {
    return pageNum * pageSize + index + 1;
  } else {
    return (pageNum - 1) * pageSize + index + 1;
  }
}

export function getPrice(num) {
  let numText = '0'
  if (num) {

    if (String(num).indexOf('.') >= 0) {

      if (String(num).indexOf('.') + 2 == String(num).length) {

        numText = num + '0'
      } else {

        numText = num
      }
    } else {
      numText = num + '.00'
    }
  }

  return numText
}
export function getVipName(storeName){
		
  if(storeName){
    if(storeName.length>4){
      return `${storeName.slice(0, 4)}...专属价`
    }else{
      return `${storeName}专属价`
    }
  }else{
    return''
  }
}