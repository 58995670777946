<template>
  <div class="header">
    <div class="fold">
      <!-- <i @click="fold" :class="isCollapse ? 'rotate180' : 'rotate0'" style="color: #ffffff;"
                class="el-icon-s-fold"></i> -->

      <img
        class="logo"
        v-if="rolechangeType == 1"
        :src="storeInfo.logo || logo"
        alt=""
      />
      <img
        class="logo"
        v-else
        :src="storeLogoImg || logo"
        alt=""
      />
      <div class="systemName" v-if="rolechangeType == 1">
        <el-dropdown trigger="click" :disabled="storeList.length == 1">
          <span class="el-dropdown-link">
            {{ storeInfo.storeName || "总平台"
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <div style="max-height: 700px; overflow-y: auto">
              <el-dropdown-item
                v-for="(item, index) in storeList"
                :key="index"
                :command="item.storeId"
                @click.native="changeStore(item.storeId, item)"
                >{{ item.storeName }}</el-dropdown-item
              >
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div class="systemName" v-else>
        <el-dropdown :disabled="storeList.length == 1">
          <span class="el-dropdown-link">
            {{ rolechangeType == 2?'门店端':'供应商端'
            }}
          </span>

        </el-dropdown>
      </div> -->

      <div class="manu_list">
        <div
          @click="menuClick(item, index)"
          :class="actMenu == Number(index + 1) ? 'act_menu' : 'manu_item'"
          v-for="(item, index) in menu_list"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <!-- 门店名称 -->
          <span v-if="roleType!=1" :style="{ marginRight: '15px' }">{{ storeName }}</span>
          <avue-avatar :src="headPortrait"></avue-avatar>
          <span :style="{ marginLeft: '15px' }">{{ fullName }}</span>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="/"> 首页 </el-dropdown-item> -->
          <!--                       <el-dropdown-item command="/info/index">-->
          <!--                            个人信息-->
          <!--                       </el-dropdown-item>-->
          <el-dropdown-item command="/password">
                      修改密码
                     </el-dropdown-item>
          <el-dropdown-item :divided="true" command="/login">
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { logout, userinfo, menuList } from "../../api";
import { mapActions } from "vuex";
import { systemUserStoreList, webLoginStore } from "@/api/system/config";
export default {
  name: "header.vue",
  props: {
    isCollapse: Boolean,
  },
  data() {
    return {
      logo: require("../../assets/images/logo.png"),
      headPortrait: "",
      fullName: "",
      menu_list: [],
      actMenu: 1,
      roleType: localStorage.getItem("roleType"), //控制业务得角色
      rolechangeType: localStorage.getItem("roleChangeType"), //控制切换 退出登录
      storeList: [],
      storeInfo: {},
      storeName:'',
      storeLogoImg:''
    };
  }, 
  created() {
    //   this.allButton('request');
    if (this.rolechangeType == 1) {
      this.getStoreList();
    } 
  },
  mounted() {
    this.$root.eventHub.$on('changeSubMenuIndex', (n) => {
      let i = n.split('-')[0]
         this.actMenu= i
        })
    // this.actMenu = this.$store.state.index.SubMenuIndex.split("-")[0];
    // console.log('actMenu', this.actMenu)
    menuList()
      .then((res) => {
        this.menu_list = res.data;
        this.$store.commit("setMenu", res.data);
        if (
          !this.$store.state.index.SubMenuIndex ||
          this.$store.state.index.SubMenuIndex == "-"
        ) {
          this.$root.eventHub.$emit("changeSubMenuIndex", "1-1");
          this.actMenu = 1;
        } else {
          this.actMenu = this.$store.state.index.SubMenuIndex.split("-")[0];
        }
        this.getUserInfo();
      })
      .catch((err) => {
        // this.$message.error(err.msg)
      });

    this.$root.eventHub.$on("changeUserInfo", (n) => {
      this.headPortrait = this.$store.state.user.userInfo.headPortrait;
    });
  },
  methods: {
    getUserInfo() {
      userinfo()
        .then((res) => {
          if (res.code === 200) {
            this.headPortrait = res.data.headPortrait;
            this.fullName = res.data.fullName;
            this.storeName = res.data.storeName;
            this.storeLogoImg = res.data.storeLogoImg;
            this.$store.commit("setUserInfo", res.data);
            //刷新浏览器保持当前的路由
            if (this.$store.state.index.SubMenuIndex == "1-1") {
              if (this.roleType == 1) {
                this.$router.push({ path: "/home/control" });
              } else if (this.roleType == 2) {
                this.$router.push({ path: "/storeHome/control" });
              } else {
                this.$router.push({ path: "/supplierHome/control" });
              }
            }
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg)
        });
    },
    changeStore(val, obj) {
      // console.log('123',val)
      if (val == this.storeInfo.storeId) {
        return;
      }
      this.storeInfo = obj;
      webLoginStore({ storeId: val }).then((res) => {
        if (res.code === 200) {
          this.$store.commit("setToken", res.data.token);
          //更新用户信息
          userinfo()
            .then((res) => {
              if (res.code === 200) {
                this.$store.commit("setUserInfo", res.data);
                this.getMenuList(val);
              }
            })
            .catch((err) => {
              // this.$message.error(err.msg)
            });
          //更新菜单
        }
      });
    },
    //切换门店的菜单赛选
    getMenuList(val) {
      menuList()
        .then((res) => {
          this.menu_list = res.data;

          this.$store.commit("setMenu", res.data);
          if (val == "admin") {
            localStorage.setItem("roleType", 1);
            this.$router.push({ path: "/home/control" });
          } else {
            localStorage.setItem("roleType", 2);
            this.$router.push({ path: "/storeHome/control" });
          }
          this.$root.eventHub.$emit("changeSubMenuIndex", "1-1");
          location.reload();
        })
        .catch((err) => {
          // this.$message.error(err.msg)
        });
    },
    //获取可以切换得门店
    getStoreList() {
      systemUserStoreList().then((res) => {
        if (res.code === 200) {
          let arr = res.data || [];
          let adminArr = [
            {
              storeId: "admin",
              storeName: "总平台",
            },
          ];
          this.storeList = [...adminArr, ...arr];
          if (this.$store.state.user.userInfo.storeId) {
            let arrChange = this.storeList.filter(
              (item) => item.storeId == this.$store.state.user.userInfo.storeId
            );
            if (arrChange.length > 0) {
              this.storeInfo = arrChange[0];
            }
          }
        }
      });
    },
    ...mapActions(["allButton"]),
    fold() {
      this.$root.eventHub.$emit("changeDate", null);
    },
    menuClick(item, index) {
      this.$router.push(item.children[0].url);
      // this.editableTabsValue = item.children[0].name;
      let MenuIndex = `${index + 1}-1`;
      this.actMenu = index + 1;
      this.$root.eventHub.$emit("changeSubMenuIndex", MenuIndex);
      // if (item.children[0].url != this.$store.state.index.router) {
      //   this.$router.push(item.children[0].url);
      //   // this.editableTabsValue = item.children[0].name;
      //   let MenuIndex = `${index + 1}-1`;
      //   this.actMenu = index + 1;
      //   this.$root.eventHub.$emit("changeSubMenuIndex", MenuIndex);
      // }
    },
    handleCommand(command) {
      if (command == "/login") {
        this.$confirm(`确认退出系统吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              logout()
                .then((res) => {
                  if (res.code === 200) {
                    // this.$router.push("/login");
                    this.global.Set_store();
                    this.global.SET_localStorage();
                    localStorage.removeItem("roleType");
                    localStorage.removeItem("roleChangeType");
                    this.rolechangeType == 1
                      ? this.$router.push("/login")
                      : this.rolechangeType == 2
                      ? this.$router.push("/platformLogin")
                      : this.$router.push("/supplierLogin");
                  }
                })
                .catch((err) => {
                  this.$message.error(err.msg);
                });
            } else {
              this.$message({
                type: "info",
                message: "已取消",
              });
            }
          },
        });
      } else {
        if (command != this.$store.state.index.router) {
          this.$router.push(command);
          let title = "";
          if (command == "/") {
            title = "首页";
          } else {
            title = "个人信息";
          }
          let data = {
            title: title,
            name: "0",
            url: command,
            index: "0",
          };
          let exist = false;
          let tableTabs = this.$store.state.index.tableTabs;
          tableTabs.forEach((item) => {
            if (item.title == title) {
              data.name = item.name;
              exist = true;
            }
          });
          if (!exist) {
            tableTabs.forEach((item, index) => {
              if (index + 1 == tableTabs.length) {
                data.name = (item.name * 1 + 1).toString();
              }
            });
          }
          this.$root.eventHub.$emit("changeSubMenuIndex", "0");
          this.$root.eventHub.$emit("changeTableTabs", { type: exist, data });
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fold {
  display: flex;
  align-items: center;
  font-size: 30px;
  height: 100%;
}

.manu_list {
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: calc(100% - 2px);
  margin-bottom: 2px;
}

.manu_item {
  width: 70px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.act_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  color: #ffffff;
  font-size: 14px;
  background-color: #4073fa;
  text-align: center;
  cursor: pointer;
  height: 100%;
}
.rotate180 {
  transition: 0.5s;
  transform: rotate(180deg);
}

.rotate0 {
  transition: 0.5s;
  transform: rotate(0deg);
}

.el-icon--right {
  margin-left: 20px;
}
.logo {
  width: 40px;
  height: 40px;
}
.el-dropdown {
  color: #ffffff;
}
.systemName {
  margin-left: 15px;
  margin-top: -13px;
  cursor: pointer;
}
</style>
