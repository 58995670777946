<template>
  <div class="box-card statistics">
    <el-card style="margin-bottom: 15px">
      <el-form size="small">
        <div>
          <el-form-item label="关联门店：" prop="storeId">
            <el-select
              v-model="tableFrom.storeId"
              placeholder="请选择"
              class="selWidth"
              clearable
              @change="getList(), getListEcharts()"
              filterable
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </el-card>

    <el-card>
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <div>
          <el-form-item label="时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
 <div class="panel-group">
      <el-card class="content"  >
        <div class="card-panel" >
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">商品数量</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.goodsNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />

            <div style="height: 30px"></div>
          </div>
        </div>
      </el-card>

      <el-card class="content" >
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">支付金额</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.payAmount"
              :duration="3000"
              :decimals="2"
              class="card-panel-num"
            />

            <div style="height: 30px"></div>
          </div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">消耗积分</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.score"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />

            <div style="height: 30px"></div>
          </div>
        </div>
      </el-card> 

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">用户总数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.userNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />

            <div style="height: 30px"></div>
          </div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">下单单数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.orderNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
            <!-- <div class="card-panel-date">
              <span class="date_text">总用户</span>
              <span class="date_num">等华子加</span>
            </div> -->
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>
      </div>
      <div class="panel-group">
      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">下单人数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.orderUserNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">支付单数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.payOrderNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel" >
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">支付人数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.payOrderUserNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">支付件数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.payGoodsNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">客单价</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.customerPrice"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>
    </div>
      <div class="panel-group">

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">单均价</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.averagePrice"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">件单价</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.goodsPrice"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">售后件数</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.afterSaleNumber"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>
     
      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">售后金额</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.afterSaleAmount"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>

      <el-card class="content">
        <div class="card-panel">
          <div class="card-panel-description">
            <div class="card-panel-text">
              <span class="card-order">售后率(%)</span>
              <span class="card-date">累计</span>
            </div>
            <count-to
              :start-val="0"
              :end-val="details.afterSaleRate*100"
              :duration="3000"
              :decimals="0"
              class="card-panel-num"
            />
          </div>
          <div style="height: 30px"></div>
        </div>
      </el-card>
    </div>
    <el-Card shadow="never" class="cardHeight">
      <div slot="header" class="clearfix">
        <span>商户销售榜单TOP 10</span>
      </div>
      <SaleTable :saleData="saleData" />
    </el-Card>

    <el-Card shadow="never" class="cardHeight">
      <div
        slot="header"
        class="clearfix"
        style="display: flex; justify-content: space-between"
      >
        <span>销售订单折线图</span>
        <div>
          <el-radio-group v-model="type" type="button" @change="getListEcharts">
            <div style="display: flex">
              <el-radio-button label="1">7天</el-radio-button>
              <el-radio-button label="2">一个月</el-radio-button>
              <el-radio-button label="3">一年</el-radio-button>
            </div>
          </el-radio-group>
        </div>
      </div>
      <echarts-from
        :option-data="optionData"
        :styles="style"
        height="100%"
        width="100%"
      />
    </el-Card>
  </div>
</template>

<script>
import {
  getStoreAllList, //门店接口
} from "@/api/user/list";
import moment from "moment";
import CountTo from "vue-count-to";
import SaleTable from "./components/saleTable";
import { getWebStoreData, getWebStoreDataType } from "@/api/data/data";
import echartsFrom from "@/components/echarts/index";
export default {
  name: "index",
  components: {
    CountTo,
    SaleTable,
    echartsFrom,
  },
  data() {
    return {
      style: { height: "400px" },
      optionData: {},
      tableFrom: {
        type: 1,
        storeId: "",
      },
      storeList: [],
      details: {},
      saleData: [],
      type: "1",
    };
  },
  created() {
    this.getStoreList();
  },
  methods: {
    //获取门店接口
    getStoreList() {
      getStoreAllList({}).then((res) => {
        this.storeList = res.data;
        this.tableFrom.storeId = res.data[0].id;
        this.getList();
        this.getListEcharts();
      });
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    //获取上面的数据
    getList() {
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.startTime =
          moment(this.tableFrom.time[0]).format("YYYY-MM-DD") + " 00:00:00";
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = null;
        this.tableFrom.endTime = null;
      }
      getWebStoreData({ ...this.tableFrom, time: null }).then((res) => {
        this.details = res.data;
        this.saleData = res.data.storeDataGoodsVOList;
      });
    },

    //获取-折线图数据
    getListEcharts() {
      getWebStoreDataType({
        storeId: this.tableFrom.storeId,
        type: this.type,
      }).then((res) => {
        this.$nextTick(() => {
          this.getEcharts(res.data.webStoreDataOrderArrayVO);
        });
      });
    },
    getEcharts(obj) {
      this.optionData = {
        xAxis: {
          type: "category",
          data: obj.dateString,
        },
        yAxis: {
          name: "订单数量",
          type: "value",
          inverse: false,
        },
        series: [
          {
            data: obj.orderNumber,
            type: "line",
            smooth: true,
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.avue-data-panel >>> .item {
  margin-bottom: 20px;
}

.avue-data-panel >>> .iconfont {
  font-size: 30px;
}
.statistics {
  min-width: 700px;
  margin-top: 0;
}

.up,
.el-icon-caret-top {
  color: #f5222d;
  font-size: 12px;
  opacity: 1 !important;
}
.down,
.el-icon-caret-bottom {
  color: #39c15b;
  font-size: 12px;
  opacity: 1;
}
.header {
  &-title {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
  }
  &-time {
    font-size: 12px;
    color: #8c8c8c;
  }
}
.card-panel {
  cursor: pointer;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  color: #8c8c8c;
  background: #fff;
  position: relative;
}
.card-panel-description {
  padding: 0 20px;
  margin-top: 19px;
  .card-panel-text {
    line-height: 18px;
    margin-bottom: 12px;
    font-weight: normal;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .card-order {
      color: #303133;
      font-size: 16px;
    }
    .card-date {
      border: 1px solid #6394f9;
      border-radius: 3px;
      color: #6394f9;
      background: #f4f7ff;
      text-align: center;
      line-height: 20px;
      width: 38px;
    }
  }
  .card-panel-num {
    font-size: 30px;
    color: #000;
    font-weight: bold;
  }
}
.card-panel-compared {
  margin: 15px 0;
}
.card-panel-date {
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
  margin-top: 20px;
}
.content {
  &-is {
    opacity: 1%;
  }

  &-title {
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
  }

  &-time {
    font-size: 12px;
    color: #8c8c8c;
    margin-bottom: 5px;
  }

  &-number {
    font-size: 30px;
  }
  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.panel-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.content {
  width: 19%;
}
.panel-group-count {
  margin-top: 18px;
  .card-panel-item {
    float: left;
  }
  .card-panel-count {
    background-color: #ffffff;
    border-radius: 4px;
    // width: 90%;
    height: 104px;
    text-align: center;
    padding-top: 20px;
    span {
      display: block;
    }
    .iconfont {
      font-size: 27px;
    }
    .panel-text {
      font-size: 14px;
      color: #303133;
      margin-top: 15px;
    }
  }
}
.cardHeight {
  height: auto;
  margin-bottom: 20px;
}
</style>
