const finance = [
    //平台财务
    {
        path: '/finance/supplier/bill',
        name: 'financeSupplierBill',
        component: () => import('@/view/finance/supplier/bill'),
    },
    {
        path: '/finance/supplier/order',
        name: 'financeSupplierOrder',
        component: () => import('@/view/finance/supplier/order'),
    },
    {  
        path: '/finance/store/bill',
        name: 'financeStoreBill',
        component: () => import('@/view/finance/store/bill'),
    },
    {
        path: '/finance/store/order',
        name: 'financeStoreOrder',
        component: () => import('@/view/finance/store/order'),
    },
    {
        path: '/finance/invoice',
        name: 'financeInvoice',
        component: () => import('@/view/finance/invoice'),
    },
    //平台得门店供应商 商品订单查看
    {
        path: '/finance/orderDetails',
        name: 'financeOrderDetails',
        component: () => import('@/view/finance/financeOrderDetails'),
    },
    //门店财务-商品订单列表
    {
        path: '/storeFinance/order',
        name: 'storeFinanceOrder',
        component: () => import('@/view/storeFinance/order'),
    },
    //门店财务-对账单管理"
    {
        path: '/storeFinance/bill',
        name: 'storeFinanceBill',
        component: () => import('@/view/storeFinance/bill'),
    },
    //供应商财务-商品订单列表
    {
        path: '/supplierFinance/order',
        name: 'supplierFinanceOrder',
        component: () => import('@/view/supplierFinance/order'),
    },
    //供应商财务-对账单管理"
    {
        path: '/supplierFinance/bill',
        name: 'supplierFinanceBill',
        component: () => import('@/view/supplierFinance/bill'),
    },
]
export default finance