const product = [
    {
        path: '/goods/list',
        name: 'goodslist',
        component: () => import('@/view/goods/list'),
    },
    {
        path: '/goods/classify',
        name: 'goodsclassify',
        component: () => import('@/view/goods/classify'),
    }, {
        path: '/goods/group',
        name: 'goodsGroup',
        component: () => import('@/view/goods/group'),
    },
    // 平台
    {
        path: '/storeGoods/list',
        name: 'storeGoodsList',
        component: () => import('@/view/storeGoods/list'),
    }, {
        path: '/storeGoods/classify',
        name: 'storeGoodsClassify',
        component: () => import('@/view/storeGoods/classify'),
    }
    , {
        path: '/storeGoods/create',
        name: 'storeGoodsCreate',
        component: () => import('@/view/storeGoods/list/create'),
    }

    // {
    //     path: '/lanxin/url',
    //     name: 'lanxinUrl',
    //     component: () => import('@/view/banner/url'),
    // },

]
export default product