let baseUrl = 'api';
// 上传图片接口
let actionUrl = baseUrl + '/web/tool/file/upload';

let videoUrl = baseUrl + '/web/tool/video/upload';
// 图片路劲
let imageUrl = 'https://ysf.java.linglongpet.com/';


let titleName = '欢迎登录宝鸟后台管理系统'

export {
    baseUrl,
    actionUrl,
    imageUrl,
    videoUrl,
    titleName
}
