<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch:{
    '$route'(to){
      this.$store.commit("setRouter",to.path);
      // sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    }
  },
  created() {
    this.global.SET_sessionStorage();
  }
}
</script>

<style lang="less">
  @import  './style/public.css';
  @import  './style/font/iconfont.css';
  *{
    margin: 0;
    padding: 0;
  }
  .tipImg{
    color: red;
    font-size: 14px;
  }
  .selWidth {
  width: 250px !important;
}

  .selCard{
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    background-color: #fff;
    padding: 15px;
    padding-bottom: 5px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    background-color: #fff;
    overflow: hidden;
    color: #303133;
    transition: .3s;

  }
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .el-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 25px;
}
.el-table thead th{
    color:#606266!important;
    background-color:#ebf1fe!important;
}
.el-button--primary {
  background-color: #4073fa !important;
}
.el-switch.is-checked .el-switch__core {
    border-color: #409EFF;
    background-color: #4073fa !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4073fa !important;
}
.el-button--text{
    color: #4073fa !important;
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #4073fa !important;
}
.el-tabs__item.is-active{
  color: #4073fa !important;
}
.el-tabs__active-bar{
  background-color: #4073fa !important;
}
.el-button.is-plain:focus, .el-button.is-plain:hover {
    background: #FFF;
    border-color: #4073fa !important;
    color: #4073fa !important;
}
.el-slider__button {
  border: 2px solid #4073fa !important;
}
.el-slider__bar{
  background-color: #4073fa !important;
}
.iconImg{
  width: 20px !important;
  height: 20px !important;
}
::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: #f5f5f5;
}


/*定义滚动条的轨道,内阴影及圆角*/

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #f5f5f5;
}


/*定义滑块,内阴影及圆角*/

::-webkit-scrollbar-thumb {
    height: 20px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px#ebebeb;
    background-color: #555;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-thumb {
    width: 7px;
    background-color: #999999;
    border-radius: 7px;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    width: 15px;
}

::-webkit-scrollbar:hover {
    background-color: transparent;
}
</style>
