import { get, put,Delete,post } from '../axios'

// 用户管理-列表
export const userManageList = params => get(`/user/web/user/manage/list`,params);

// 用户管理-状态修改
export const userManageStateUpdate = params => put(`/user/web/user/manage/state/update`,params);


//用户反馈-列表
export const userFeedbackList= params => get(`/user/web/userFeedback/list`,params);
//用户反馈-查看
export const userFeedbackDetial= params => get(`/user/web/userFeedback/view/${params}`); 

//用户列表
export const clientUserList = params => get(`/user/web/clientUser/list`,params);
//门店端-用户导入-下载文件模板
export const clientUserTemplateExport = params => get(`/user/web/clientUser/template/export`,params);
//门店端-用户导入
export const clientUser = params => post(`/user/web/clientUser/import`,params);
//门店端-用户导入-下载问题数据
export const clientUserErrorExport = params => get(`/user/web/clientUser/error/export`,params);
//门店端-用户管理-新增
export const clientUserAdd = params => post(`/user/web/clientUser/add`,params);
//门店端-用户管理-详情
export const clientUserDetail = params => get(`/user/web/clientUser/detail/${params}`);
//门店端-用户管理-冻结解冻
export const clientUserUpdate = params => get(`/user/web/clientUser/state/update`,params);
//门店端-用户管理-删除
export const clientUserDel = params => Delete(`/user/web/clientUser/detail/${id}`);
//所有门店列表-不分页
export const getStoreAllList= params => get(`/user/web/store/allList`,params);
//平台端-所有部门-不分页
export const getDeptAllList= params => get(`/user/web/store/dept/allList`,params);