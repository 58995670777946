const order = [
  // 商品品牌
  {
    path: "/supplierGoods/brand",
    name: "supplierGoodsBrand",
    component: () => import("@/view/supplierGoods/brand"),
  },
  // 商品列表
  {
    path: "/supplierGoods/list",
    name: "supplierGoodsList",
    component: () => import('@/view/supplierGoods/list'),
  },
  // 商品分组
  {
    path: "/supplierGoods/group",
    name: "supplierGoodsGroup",
    component: () => import("@/view/supplierGoods/group"),
  },
    // 商品分类
    {
      path: "/supplierGoods/classify",
      name: "supplierGoodsClassify",
      component: () => import("@/view/supplierGoods/classify"),
    },
  // 添加商品
  {
    path: "/supplierGoods/create",
    name: "supplierGoodsCreate",
    component: () => import("@/view/supplierGoods/list/create"),
  },
  // 规格管理
  {
    path: "/supplierGoods/spec",
    name: "supplierGoodsSpec",
    component: () => import("@/view/supplierGoods/spec"),
  },
  // 子规格管理
  {
    path: "/supplierGoods/spec/specSon",
    name: "supplierGoodsSpecSpecSon",
    component: () => import("@/view/supplierGoods/spec/specSon"),
  },
    // 素材库
    {
      path: "/supplierGoods/material",
      name: "supplierGoodsMaterial",
      component: () => import("@/view/supplierGoods/material/index"),
    },
    // 商品单位
    {
      path: "/supplierGoods/unit",
      name: "supplierGoodsUnit",
      component: () => import("@/view/supplierGoods/unit"),
    },
];
export default order;
