const user = {
    state: {
        userInfo: {},
        keepList:['orderlist'],
        Token: '',
    },
    mutations: {
        setToken(state,info){
            state.Token = info
            // state.Token = 'info'
        },
        setUserInfo(state,info){
            state.userInfo = info
        },
        setKeepList(state,info){
            state.keepList = info
        }
    }

}
export default user
