<template>
    <div class="el-menu-vertical-demo">
        <el-menu v-if="menu_list.length" @select="handleSelect" background-color="#ffffff" text-color="#333" :collapse="isCollapse"
            active-text-color="#ffffff" :default-active="subMenuActIndex" :unique-opened="true">
            <div v-for="(item, index) in menu_list[subMenuIndex.split('-')[0] - 1].children" :key="index">
                <div v-if="item.children">
                    <el-submenu :index="(index + 1).toString()">
                        <template slot="title">
                            <!-- <i :class="item.icon || 'el-icon-location'"></i> -->
                            <span>{{ item.name }}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-for="(itemC, indexC) in item.children" :key="indexC"
                                :index="(index + 1).toString() + '_' + (indexC + 1).toString()"
                                @click.native="openMenu(itemC.url, itemC.name)">
                                <!-- <i :class="itemC.icon || 'el-icon-location'"></i> -->
                                <span>{{ itemC.name }}</span>
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </div>
                <el-menu-item v-else :index="(index + 1).toString()" @click.native="openMenu(item.url, item.name)">
                    <!-- <i :class="item.icon || 'el-icon-location'"></i> -->
                    <span>{{ item.name }}</span>
                </el-menu-item>
            </div>
        </el-menu>
    </div>
</template>

<script>
import { menuList } from '@/api'
export default {
    name: "navigationMenu",
    data() {
        return {
            isCollapse: false,
            menu_list: [],
            subMenuIndex: this.$store.state.index.SubMenuIndex,
            subMenuActIndex:'1'
        }
    },
    mounted() {
        this.$root.eventHub.$on('changeDate', this.change)
        menuList().then(res => {
            this.menu_list = res.data;
            this.$store.commit("setMenu", res.data);
        }).catch(err => {
            // this.$message.error(err.msg)
        })

        this.$root.eventHub.$on('changeSubMenuIndex', (n) => {
            this.subMenuActIndex = ''
            this.subMenuActIndex=n.split('-')[1]+''
            // this.subMenuIndex = n;
            this.subMenuIndex = n;
            this.$store.commit("setSubMenuIndex", n);
            this.handleSelect(n)
        })
        if (this.$store.state.index.SubMenuIndex != "1-1") {
            this.handleSelect(this.$store.state.index.SubMenuIndex)
        }

    },
    methods: {
        change() {
            let that = this;
            that.isCollapse = !that.isCollapse
        },
        handleSelect(key) {
          
            if (key.includes('-')) {
                this.$store.commit("setKeepList", []);
                this.subMenuActIndex=key.split('-')[1]+''
                this.$store.commit("setSubMenuIndex", key);
                let indexA = key.substr(0, key.indexOf("-"));
                let indexB = key.substring(key.lastIndexOf("-") + 1, key.length);
                if(this.$store.state.index.menu[indexA - 1]&&this.$store.state.index.menu[indexA - 1].children[indexB - 1].children){
                    let arr = this.$store.state.index.menu[indexA - 1].children[indexB - 1].children
                    this.subMenuActIndex='1_1'
                    this.$router.push(arr[0].url);
                    // this.$store.commit("setMenuSubButtons", this.$store.state.index.menu[indexA - 1].children[indexB - 1].children);
                }
    
            }else{
                let indexA =  this.subMenuIndex.split('-')[0]
                this.$store.commit("setSubMenuIndex", `${indexA}-${key}`);
                this.subMenuActIndex=key
            }
        },
        openMenu(url, name) {
            console.log('131',url)
            if(url!='/order/list'&&url!='/storeOrder/list'){
                this.$store.commit("setKeepList", []);
                // this.$router.removeInclude('orderlist')
            }
            if (url != this.$store.state.index.router) {
                this.$router.push({ path: url })
                let tableTabs = this.$store.state.index.tableTabs;
                let data = {
                    title: name,
                    name: '0',
                    url: url,
                    index: this.$store.state.index.SubMenuIndex
                }
                let exist = false;
                tableTabs.forEach(item => {
                    if (item.title == data.title) {
                        data.name = item.name;
                        exist = true;
                    }
                })
                if (!exist) {
                    tableTabs.forEach((item, index) => {
                        if (index + 1 == tableTabs.length) {
                            data.name = (item.name * 1 + 1).toString()
                        }
                    })
                }
                this.$root.eventHub.$emit('changeTableTabs', { type: exist, data });
            }
        }
    }
}
</script>

<style  scoped>
.el-menu-vertical-demo {
    width: 100%;
    height: calc(100% - 100px);
    background-color: #ffffff;
    overflow-x: hidden;
    overflow: auto;
}

.el-menu-vertical-demo::-webkit-scrollbar {
    display: none;
}

.el-menu-vertical-demo .el-menu {
    border: none !important;
}

.el-menu-item-group .el-menu-item-group__title {
    padding: 0;
}

.el-sub-menu:hover {
    background-color: aqua !important;
}

.is-active {
    color: #ffffff;
    background-color: #4073fa !important;
}
</style>
