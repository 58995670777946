const order = [
    {
        path: '/supplierOrder/list', 
        name: 'supplierOrderList',
        component: () => import('@/view/supplierOrder/list'),
    },
    {
        path: '/supplierOrder/refund', 
        name: 'supplierOrderRefund',
        component: () => import('@/view/supplierOrder/saleList'),
    },
    {
        path: '/system/refund/address', 
        name: 'refundAddress',
        component: () => import('@/view/supplierOrder/settingAfter'),
    },
]
export default order