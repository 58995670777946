const decorate = [
    {
        path: '/decorate/page',
        name: '/decorate/page',
        component: () => import('@/view/decorate/page/index'),
    },
    {
        path: '/pagesCustom/index',
        name: '/pagesCustom/index',
        component: () => import('@/view/pagesCustom/index'),
    },
    {
        path: '/decorate/material',
        name: '/decorate/material',
        component: () => import('@/view/decorate/material'),
    },
    {
        path: '/decorate/article',
        name: '/decorate/article',
        component: () => import('@/view/decorate/article'),
    },
]
export default decorate