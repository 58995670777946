const pagesCustom = {
    state: {
        pageInfo:[],
        pageId:''
    },
    mutations: {
        setPage(state,info){
            state.pageInfo = info
        },
        setPageId(state,info){
            state.pageId = info
        }
    }

}
export default pagesCustom
