const store = [
    {
        path: '/store/list',
        name: 'storeList',
        component: () => import('@/view/store/list'),
    },
    {
        path: '/store/member',
        name: 'storeMember',
        component: () => import('@/view/store/member'),
    },
    {
        path: '/store/goods',
        name: 'storeGoods',
        component: () => import('@/view/store/goods'),
    },
    {
        path: '/store/goods/classify',
        name: 'storeGoodsClassify',
        component: () => import('@/view/store/goods/classify'),
    },
    {
        path: '/store/integral',
        name: 'storeIntegral',
        component: () => import('@/view/store/integral'),
    },
]
export default store