import {get, post, put,Delete} from '../axios'
// 角色管理-列表
export const roleList = params => get('/user/web/system/role/list',params);

// 角色管理-新增
export const roleAdd = params => post('/user/web/system/role/add',params);

// 角色管理-查看
export const roleView = params => get(`/user/web/system/role/view/${params}`);

// 角色管理-修改
export const roleUpdate = params => put('/user/web/system/role/update',params);

// 角色管理-删除
export const roleDelete = params => Delete(`/user/web/system/role/delete/${params}`);

// 角色管理-菜单树形结构
export const roleMenuTree = params => get(`/user/web/system/role/menu/tree/${params}`);

// 角色管理-查看角色菜单
export const roleMenuView = params => get(`/user/web/system/role/menu/view/${params}`);

// 角色管理-角色菜单修改
export const roleMenuUpdate = params => put('/user/web/system/role/menu/update',params);