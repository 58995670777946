import {get, post, put,Delete} from '../axios'
/* 用户管理-列表 */
export const userlist = params => get('/user/web/system/user/list',params);

// 用户管理-查看
export const userView = params => get(`/user/web/system/user/view/${params}`);

// 用户管理-修改
export const userUpdate = params => put('/user/web/system/user/update',params);

// 用户管理-新增
export const userAdd = params => post('/user/web/system/user/add',params);

// 用户管理-删除
export const userDelete = params => Delete(`/user/web/system/user/delete/${params}`);

// 用户管理-角色列表
export const userRole = params => get('/user/web/system/user/role',params);


// 用户管理-用户角色查看
export const roleView = params => get(`/user/web/system/role/view/${params}`);

// 用户管理-用户角色修改
export const roleUpdate = params => put('/user/web/system/role/update',params);

// 用户管理-密码重置
export const passwordReset = params => get(`/user/web/system/user/password/reset/${params}`);

// 用户管理-状态
export const userStateUpdate = params => put(`/user/web/system/user/state/update`,params);

// 用户管理-归属上级-列表
export const parentList = params => get(`/user/web/system/user/parent/list`,params);

// 获取省市区
export const getRegionList = params => get(`/user/web/region/list`,params);
// 获取省市区-保存
export const RegionListSave = params => post(`/user/web/storeRegion/save`,params);
// 获取省市区-查询
export const getStoreRegion = params => get(`/user/web/storeRegion/get`,params);



  