import {get, post, put,Delete} from '../axios'

//系统配置-列表
export const mallConfigList = params => get(`/system/web/mallConfig/list`,params);

//系统配置-新增or编辑
export const mallConfigUpdate = params => put(`/system/web/mallConfig/update`,params);

//系统配置-查看根据fieldKey查询
export const getMallConfigByKey = params => put(`/system/web/mallConfig/view/${params}`);

//富文本协议-列表
export const userAgreementList = params => get(`/system/web/userAgreement/list`,params);

//富文本协议-查看
export const userAgreementDetail = params => get(`/system/web/userAgreement/view/${params}`);

//富文本协议-编辑
export const userAgreementUpdate = params => put(`/system/web/userAgreement/update`,params);

//小程序配置表-查看
export const appletConfigView = params => get(`/system/core/appletConfig/view`,params);

//小程序配置表-新增or编辑
export const appletConfigSaveOrUpdate = params => put(`/system/core/appletConfig/saveOrUpdate`,params);

//小程序支付配置表-查看
export const appletPayConfigView = params => get(`/system/core/appletPayConfig/view`,params);

//小程序支付配置表-新增or编辑
export const appletPayConfigUpdate = params => put(`/system/core/appletPayConfig/saveOrUpdate`,params);

//服务器配置表-列表
export const serverConfigList = params => get(`/system/core/serverConfig/list`,params);

//服务器配置表-新增
export const serverConfigAdd = params => post(`/system/core/serverConfig/add`,params);

//服务器配置表-查看
export const serverConfigView = params => get(`/system/core/serverConfig/view/${params}`);

//服务器配置表-编辑
export const serverConfigUpdate = params => put(`/system/core/serverConfig/update`,params);

//服务器配置表-删除
export const serverConfigDelete = params => Delete(`/system/core/serverConfig/delete/${params}`);

//用户管理-门店列表(平台专用-用于切换店铺)
export const systemUserStoreList = params => get(`/user/web/system/user/storeList`,params);

//换到门店
export const webLoginStore = params => post(`/user/web/loginStore`,params);

//快递公司-列表
export const expressCompanyList = params => get(`/order/web/expressCompany/manage/list`,params);

//快递公司-新增
export const expressCompanyAdd = params => post(`/order/web/expressCompany/manage/add`,params);

//快递公司-编辑
export const expressCompanyUpdate = params => put(`/order/web/expressCompany/manage/update`,params);

//快递公司-编辑
export const expressCompanyDel = params =>  Delete(`/order/web/expressCompany/manage/delete/${params}`);

//快递公司-详情
export const manageView = params =>  get(`/order/web/expressCompany/manage/view/${params}`);

//OSS配置表-查看
export const ossConfigView = params => get(`/system/core/ossConfig/view`,params);
//OSS配置表-新增or编辑
export const ossConfigsaveOrUpdate = params => put(`/system/core/ossConfig/saveOrUpdate`,params);

//OSS配置表-新增or编辑
export const getLoginBackImg = params => get(`/system/web/mallConfig/getLoginBackImg`,params);



 
   