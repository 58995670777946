const user = [
    {
        path: '/member/list',
        name: 'memberlist',
        component: () => import('@/view/member/list'),
    },
    {
        path: '/member/feedback',
        name: 'memberfeedback',
        component: () => import('@/view/member/feedback'),
    }
]
export default user