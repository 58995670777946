<template>
  <div >
    <el-table
    :data="saleData"
    style="width: 100%">
       <el-table-column
      fixed
      type="index"
      align="center"
      label="序号">
    </el-table-column>
    <el-table-column
      fixed
      prop="goodsName"
      align="center"
      label="商品名称">
    </el-table-column>
    <el-table-column
      prop="goodsNumber"
      align="center"
      label="商品销量">
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
export default {
  name: "saleTable",
  props: {
    saleData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">

</style>
