import { get, put,Delete,post } from '../axios'

// 门店数据汇总
export const getWebStoreData = params => get(`/order/web/data/getWebStoreDataVO`,params);

// 门店数据汇总-折线图
export const getWebStoreDataType = params => get(`/order/web/data/getWebStoreDataTypeVO`,params);

// 供应商数据汇总
export const getWebVendorData = params => get(`/order/web/data/getWebVendorDataVO`,params);

// 供应商数据汇总7天一个月一年
export const getWebVendorDataType = params => get(`/order/web/data/getWebVendorDataTypeVO`,params);

// 门店二维码
export const getStoreQrcode = params => get(`/user/web/store/qrcode`,params);



