const freight = [
    {
        path: '/storeGoods/template',
        name: 'storeGoodsTemplate',
        component: () => import('@/view/storeGoods/template/index'),
    },
    {
        path: '/manage/create',
        name: 'manageCreate',
        component: () => import('@/view/storeGoods/template/create'),
    },
    // {
    //     path: '/lanxin/url',
    //     name: 'lanxinUrl',
    //     component: () => import('@/view/banner/url'),
    // },
]
export default freight