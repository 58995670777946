<template>
    <div class="search">
      <el-date-picker
          v-model="form.date"
          type="daterange"
          range-title="注册时间"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-picker margin"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
      ></el-date-picker>
        <el-input v-model="form.name" placeholder="请输入手机号/用户昵称" clearable class="margin"></el-input>
      <el-select v-model="form.state" placeholder="账号状态" clearable class="margin">
        <el-option v-for="item in state" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
        <el-button type="primary" @click="searchQuery" style="margin-bottom: 10px">查询</el-button>
        <el-button type="info" @click="searchReset" style="margin-bottom: 10px">重置</el-button>

    </div>
</template>

<script>
import column from "./column"
    export default {
        name: 'search',
        data() {
            return {
                form: {
                    name: '', //活动标题
                },
                state: column.state,
            };
        },
      watch: {
        'form.date'(newValue) {
          if (newValue != null) {
            newValue.forEach((item, index) => {
              if (index === 0) {
                this.form.createTimeStart = item + ' 00:00:00';
              } else {
                this.form.createTimeEnd = item + ' 23:59:59';
              }
            });
          } else {
            this.form.createTimeStart = '';
            this.form.createTimeEnd = '';
          }
        }
      },
        methods: {
            increase(){
                this.$emit('changeIncrease');
            },
            searchQuery() {
                this.$emit('changeSearch', this.form);
            },
            searchReset() {
                this.form = {
                    name: '', //活动标题
                };
                this.$emit('changeSearch', this.form);
            }
        }
    };
</script>

<style scoped>
.search {
  display: flex;
}
.date-picker >>> .el-icon-date {
  margin-left: 10px;
}
.date-picker >>> .el-range-title {
  width: 20% !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.date-picker >>> .el-range-separator {
  padding: 0;
}
.date-picker >>> .el-range-input {
  width: 30% !important;
}
.search > .el-range-editor.el-input__inner {
  padding: 3px 0px;
}
.search > .el-select {
  width: 200px;
}
.search > .el-input {
  width: 300px;
}
.margin{
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
