const balance = [
    {
        path: '/balance/acticity',
        name: '/balanceActicity',
        component: () => import('@/view/balance/acticity'),
    },
    {
        path: '/balance/desc',
        name: '/balanceDesc',
        component: () => import('@/view/balance/desc'),
    },
    {
        path: '/balance/record',
        name: '/balanceRecord',
        component: () => import('@/view/balance/record'),
    }
]
export default balance