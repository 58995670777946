const order = [
    {
        path: '/order/list',
        name: 'orderlist',
        component: () => import('@/view/order/list'),
        meta: {
            keepAlive: true
          }
    },
    {
        path: '/order/details',
        name: 'orderDetails',
        component: () => import('@/view/order/orderDetails'),
    },
    {
        path: '/order/refund',
        name: 'orderRefund',
        component: () => import('@/view/order/refund'),
    }
    ,
    {
        path: '/order/returnOrderInfo',
        name: 'orderReturnOrderInfo',
        component: () => import('@/view/order/returnOrderInfo'),
    }
]
export default order