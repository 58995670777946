import { userRole,parentList } from "@/api/system/index";

const system = {
    state: {
        role: [],
    },
    mutations: {
        setRole(state,info) {
            state.role = info;
        },
    },
    actions: {
        async getRole({commit}) {
            const result = await userRole().then(res => {
                return res.data;
            });
            commit('setRole', result);
        },
    }

}
export default system
