const column = {
    column: [
        {
            label: '菜单名称',
            prop: 'name'
        },
        {
            label: '排序',
            prop: 'sort'
        }
    ]
}
export default column
