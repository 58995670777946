const storeOrder = [
    {
        path: '/storeOrder/list',
        name: 'storeOrderlist',
        component: () => import('@/view/storeOrder/list'),
    },
    {
        path: '/storeOrder/refund',
        name: 'membeStoreOrderRefund',
        component: () => import('@/view/storeOrder/refund'),
    },
    // {
    //     path: '/storeMember/integral',
    //     name: 'membestoreMemberIntegralt',
    //     component: () => import('@/view/storeMember/integral'),
    // }
]
export default storeOrder