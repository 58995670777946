<template>
    <el-dialog title="编辑"
               width="600px"
               v-dialogdrag
               :visible.sync="increase.type"
               :close-on-click-modal="false"
               :show-close="false"
               :before-close="handleClose">
        <avue-form :option="option" v-model="form" ref="form">
        </avue-form>
        <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="handleClose">取 消</el-button>
              <el-button
                  type="primary"
                  size="small"
                  @click="handleSubmit(form)">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { systemManageView,systemManageUpdate } from "@/api/system/list";
    export default {
        name: "increase",
        props: {
            increase: Object
        },
        data(){
            return{
                form: {},
                option: {
                    labelWidth: 120,
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label:'菜单名称',
                            prop:'name',
                            type: 'input',
                            placeholder: '最多输入5个字',
                            maxlength: 5,
                            span: 20,
                            rules: [{
                                required: true,
                                message: "请输入菜单名称",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'排序值',
                            prop:'sort',
                            type: 'number',
                            minRows: 1,
                            maxRows: 99999,
                            span: 20,
                            rules: [{
                                required: true,
                                message: "请输入排序值",
                                trigger: "blur"
                            }]
                        }
                    ]
                }
            }
        },
        mounted() {
            if(this.increase.id != ''){
                this.GET_identityView()
            }
        },
        methods: {
            salaryChange(e){
                this.form.sort = e.target.value;
            },
            handleSubmit(form){
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                      this.GET_identityUpdate(form,done);
                    }
                })
            },

            GET_identityUpdate(form,done){
                systemManageUpdate({
                    id: form.id,
                    name: form.name,
                    sort: form.sort,
                }).then(res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeIdentityIncrease', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_identityView(){
                systemManageView(this.increase.id).then(res=>{
                    // console.log(res)
                    if(res.code === 200){
                        this.form = res.data;
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    this.$message.error(err.msg)
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeIdentityIncrease', false);
            }
        }
    }
</script>

<style scoped>

</style>
